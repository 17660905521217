import { createStore } from "vuex";
import LoginStore from "./modules/login";
import Breadcrumb from "./modules/breadcrumb";
import Vuex from "vuex";

export default createStore({
  modules: {
    login: LoginStore,
    breadcrumb: Breadcrumb
  },
  state: {},
  mutations: {},
  actions: {},
  getters: {
    currentUser(state) {
      return state.login.currentUser
    },
    userPermission: (state) => state.login.userPermission,
    token: (state) => state.login.token,
    matched: (state) => state.breadcrumb.matched
  }
});
