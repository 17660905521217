import axios from "../axios-config";
import { LoginForm } from "@/ts-type/login";
import { AxiosRequestConfig } from "axios";
import { encrypt } from '@/utils/rsaEncrypt'
import { encStr } from '@/utils/encryption'
import qs from 'qs'

/**
 * 登录接口
 * @param {*} loginForm
 */
export function login(loginForm:any) {
  const data = {
    username: loginForm.phone,
    password: encrypt(loginForm.password)
  }
  return axios.post("/auth/login", {...data});
}
/**
 * 登录接口
 * @param {*}
 */
 export function getCurrentUser() {
  return axios.get("/auth/current");
  // return new Promise((resolve) => {
  //   // setTimeout(() => {
  //   //   resolve({
  //   //     username: 'test1',
  //   //     name: '测试',
  //   //     userId: '1111',
  //   //     userPermission: [],
  //   //     userImg: 'http://8.142.218.12:9000/test/safety-20221112-5e9ea56ecdf54670a5530a533d343ba0.png'
  //   //   })
  //   // }, 100)
  // })
}


