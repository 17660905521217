import axios, {AxiosRequestConfig} from "axios";
import store from '@/store'
import notification from "ant-design-vue/es/notification";
import * as _ from "lodash";
import { useCookies } from "vue3-cookies";
import  qs from 'qs'

import LoginStore from "@/store/modules/login";
import { getModule } from "vuex-module-decorators";
import { useRouter } from "vue-router";

const { cookies } = useCookies();

const _debounceMessage = _.debounce(notification.error, 1500, {
  leading: true,
  trailing: false,
});

const option: AxiosRequestConfig ={
  baseURL: process.env.VUE_APP_API_BASE_URL,
   timeout: 120000,// 请求超时 40s
}

const instance = axios.create(option);

// 添加请求拦截器
instance.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    //只针对get方式进行序列化
    // console.log(process.env.NODE_ENV);

 if (config.method === 'get') {
  config.paramsSerializer = function(params) {
    return qs.stringify(params, { arrayFormat: 'repeat' })
  }
}
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization : cookies.get("TOKEN_ACCOUNT") ? 'Bearer '+ cookies.get("TOKEN_ACCOUNT") : null,
      },
    };
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
instance.interceptors.response.use(
  function (response) {
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    if(!(response.data.type ==  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")){
      if (!response.data.success) {
        if(response.data.errMessage=='token过期'){
          _debounceMessage({
            message: "登录提示",
            description:"您的账号认证已过期，请重新登录",
          });
          const loginStore = getModule(LoginStore, store)
          const router = useRouter()
          loginStore.layout().then(() => router.push('/login'))
        }else{
          _debounceMessage({
            message: response.data.errMessage,
          });
        }
      }
    }
    return response.data;
  },
  function (error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);

export default instance;
