import { RouteRecordRaw } from "vue-router";
import { Route } from "../ts-type/routes";
import store from '@/store'

export const staticRoutes: Array<RouteRecordRaw> = [
  {
    path: "/:catchAll(.*)",
    redirect: "/",
  },
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login.vue"),
  },
  {
    path: "/home",
    name: "home",
    component: () => import("@/views/home/index.vue"),
  },
  {
    path: "/not-permission",
    component: () => import("@/layout/index.vue"),
    children: [
      {
        path: "/not-permission",
        component: () => import("@/views/notPage/not-permission.vue"),
      },
    ],
  },
];

export const asyncRoutes: Array<Route> = [
  {
    path: "/visitorsTraining",
    name: "visitorsTraining",
    meta: {
      title: "访客培训管理",
      icon: "worker",
    },
    component: () => import("@/layout/index.vue"),
    children: [
      {
        path: "/visitorsTraining/factoryConstruction",
        name: "factoryConstruction",
        meta: {
          title: "进厂施工培训",
          icon: "",
        },
        component: () =>
          import("@/views/visitorsTraining/factoryConstruction/index.vue"),
      },
      {
        path: "/visitorsTraining/delivery",
        name: "delivery",
        meta: {
          title: "进厂送货培训",
          icon: "",
        },
        component: () =>
          import("@/views/visitorsTraining/delivery/index.vue"),
      },
      {
        path: "/visitorsTraining/businessCommunication",
        name: "businessCommunication",
        meta: {
          title: "业务沟通培训",
          icon: "",
        },
        component: () =>
          import("@/views/visitorsTraining/businessCommunication/index.vue"),
      },
      {
        path: "/visitorsTraining/visitorsLog",
        name: "visitorsLog",
        meta: {
          title: "访客培训日志",
          icon: "",
        },
        component: () =>
          import("@/views/visitorsTraining/visitorsLog/index.vue"),
      },
    ],
  },
  {
    path: "/question",
    name: "question",
    meta: {
      title: "题库管理",
      icon: "receive",
    },
    component: () => import("@/layout/index.vue"),
    children: [
      {
        path: "/question/type",
        name: "questionType",
        meta: {
          title: "题库类别",
          icon: "",
        },
        component: () => import("@/views/question/questionType/index.vue"),
      },
      {
        path: "/question/managemant",
        name: "questionManagemant",
        meta: {
          title: "试题管理",
          icon: "",
        },
        component: () =>
          import("@/views/question/questionManagement/index.vue"),
      },
    ],
  },
  {
    path: "/meaterial",
    name: "meaterial",
    meta: {
      title: "资料管理",
      icon: "document-folder",
    },
    component: () => import("@/layout/index.vue"),
    children: [
      {
        path: "/meaterial/type",
        name: "meaterialType",
        meta: {
          title: "资料类别",
          icon: "",
        },
        component: () => import("@/views/meaterial/meaterialType/index.vue"),
      },
      {
        path: "/meaterial/documentation",
        name: "documentation",
        meta: {
          title: "资料管理",
          icon: "",
        },
        component: () => import("@/views/meaterial/documentation/index.vue"),
      },
    ],
  },
  {
    path: "/trainingPlan",
    name: "trainingPlan",
    meta: {
      title: "培训计划管理",
      icon: "classroom-6d8jllel",
    },
    component: () => import("@/layout/index.vue"),
    children: [
      {
        path: "/trainingPlan/type",
        name: "trainingType",
        meta: {
          title: "培训类别",
          icon: "",
        },
        component: () => import("@/views/trainingPlan/trainingType/index.vue"),
      },
      {
        path: "/trainingPlan/onlineTraining",
        name: "onlineTraining",
        meta: {
          title: "线上培训计划",
          icon: "",
        },
        component: () =>
          import("@/views/trainingPlan/onlineTraining/index.vue"),
      },
      {
        path: "/trainingPlan/offlineTraining",
        name: "offlineTraining",
        meta: {
          title: "线下培训计划",
          icon: "",
        },
        component: () =>
          import("@/views/trainingPlan/offlineTraining/index.vue"),
      },
    ],
  },
  {
    path: "/examManagement",
    name: "examManagement",
    meta: {
      title: "考试管理",
      icon: "table-file",
    },
    component: () => import("@/layout/index.vue"),
    children: [
      {
        path: "/examManagement/paperManagement",
        name: "paperManagement",
        meta: {
          title: "考卷管理",
          icon: "notes",
        },
        component: () =>
          import("@/views/examManagement/paperManagement/index.vue"),
      },
      {
        path: "/examManagement/testPlan",
        name: "testPlan",
        meta: {
          title: "考试计划管理",
          icon: "plan",
        },
        component: () => import("@/views/examManagement/testPlan/index.vue"),
      },
      {
        path: "/examManagement/testScores",
        name: "testScores",
        meta: {
          title: "考试成绩管理",
          icon: "table-file",
        },
        component: () => import("@/views/examManagement/testScores/index.vue"),
      },
    ],
  },
  {
    path: "/statisticalManagement",
    name: "statisticalManagement",
    meta: {
      title: "统计查询",
      icon: "dashboard-one",
    },
    component: () => import("@/layout/index.vue"),
    children: [
      {
        path: "/statisticalManagement/questionBankPractice",
        name: "questionBankPractice",
        meta: {
          title: "题库练习统计",
          icon: "",
        },
        component: () =>
          import(
            "@/views/statisticalManagement/questionBankPractice/index.vue"
          ),
      },
      {
        path: "/statisticalManagement/testScores",
        name: "test",
        meta: {
          title: "考试成绩统计",
          icon: "",
        },
        component: () =>
          import("@/views/statisticalManagement/testScores/index.vue"),
      },
      {
        path: "/statisticalManagement/LengthStudy",
        name: "LengthStudy",
        meta: {
          title: "学习时长统计",
          icon: "",
        },
        component: () =>
          import("@/views/statisticalManagement/LengthStudy/index.vue"),
      },
    ],
  },
  {
    path: "/stipulate",
    name: "stipulate",
    meta: {
      title: "培训有效学时管理",
      icon: "permissions",
    },
    component: () => import("@/layout/index.vue"),
    children: [
      {
        path: "/stipulate/stipulateTime",
        name: "stipulateTime",
        meta: {
          title: "用户个人规定学时",
          icon: "",
        },
        component: () =>
          import("@/views/stipulate/stipulateTime/index.vue"),
      }
    ]
  }
  // {
  //   path: "/rightsManagement",
  //   name: "rightsManagement",
  //   meta: {
  //     title: "权限管理",
  //     icon: "permissions",
  //   },
  //   component: () => import("@/layout/index.vue"),
  //   children: [
  //     {
  //       path: "/rightsManagement/userManagement",
  //       name: "userManagement",
  //       meta: {
  //         title: "用户管理",
  //         icon: "",
  //       },
  //       component: () =>
  //         import("@/views/rightsManagement/userManagement/index.vue"),
  //     },
  //     {
  //       path: "/rightsManagement/roleManagement",
  //       name: "roleManagement",
  //       meta: {
  //         title: "角色管理",
  //         icon: "",
  //       },
  //       component: () =>
  //         import("@/views/rightsManagement/roleManagement/index.vue"),
  //     },
  //     {
  //       path: "/rightsManagement/menuManagement",
  //       name: "menuManagement",
  //       meta: {
  //         title: "菜单管理",
  //         icon: "",
  //       },
  //       component: () =>
  //         import("@/views/rightsManagement/menuManagement/index.vue"),
  //     },
  //   ],
  // },
];

const routes: Array<any> = [...staticRoutes,...asyncRoutes ];

export default routes;
